import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { noop } from 'lodash';

import { useModal, useNotify } from '@moved/services';
import { LoaderOverlay } from '@moved/ui';

import { FinalizeJobModal } from './FinalizeJobModal';
import { getJobDetails } from '../actions';

export const FinalizeJob = ({parent, match, refreshJobs=noop}) => {
  const { active } = useParams();
  const history = useHistory();
  const Modal = useModal();
  const Notify = useNotify();
  const dispatch = useDispatch();

  // redux
  const pending = useSelector(state => state.requests.JOBS_GET_JOB_DETAILS_PENDING);

  useEffect(() => {
    dispatch(getJobDetails(active))
      .then(job => {
        if(job.status !== 'In Transit') return history.replace(parent);
        Modal.open(<FinalizeJobModal job={job} />, {
          onClose: (result) => {
            if(result) refreshJobs(); // passed down from parent BookedJobsList
            result ? history.replace(parent) : history.push(parent);
          },
        });
      })
      .catch(() => Notify.error(`We're sorry, we were unable to fetch information about that job.`));
  // eslint-disable-next-line
  },[active]);

  return pending ? (<LoaderOverlay/>) : null;
};
