import React from 'react';
import { NavLink } from 'react-router-dom';

import { useUser } from '@moved/services';

import { useVendor } from '../actions/selectors';
import { ProfileLink } from './ProfileLink';

import navCSS from '../styles/AppNavigation.module.scss';

export const AppNavigation = ({ open }) => {
  const vendor = useVendor();
  const { hasDomain } = useUser();

  return (
    <div className={[navCSS.navigation, open ? navCSS.active: ''].join(' ')}>
      { hasDomain('vendor') && (
        <ul className={navCSS.menu}>
          <li>
            <NavLink to="/jobs" activeClassName={navCSS.active} className="flex">
              <span>Booked Jobs</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/calendar" activeClassName={navCSS.active} className="flex">
              <span>Calendar</span>
            </NavLink>
          </li>
        </ul>
      )}
      { hasDomain('guest') && (
        <ul className={navCSS.menu}>
          <li className={navCSS.primary}>
            <NavLink to="/signin" activeClassName={navCSS.active} className="flex">
              <span>Signin</span>
            </NavLink>
          </li>
        </ul>
      )}
      { hasDomain('vendor') && vendor && (
        <div className={navCSS.bottom_section}>
          <ProfileLink/>
        </div>
      )}
    </div>
  );
};
