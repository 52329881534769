import { generate, request } from '@moved/services';

const creator = {
  namespace: 'COMMON_GET_VENDOR',
  request: () => request.get(`/vendors/current`).then(r => r.data.data.vendor),
  selector: state => state.vendor,
  cache: { minutes: 15 },
  reducers: {
    success: (state, { response: vendor }) => {
      return {
        ...state,
        vendor: vendor
      };
    },
  }
};

export const {
  action,
  reducer,
  useResource: useVendor,
  usePending: useVendorPending,
} = generate.redux(creator);

export const initialState = {
  vendor: null,
};
