import { merge } from 'lodash';
import { generate } from '@moved/services';

import * as common from '../features/common/actions/reducer';
import * as availability from '../features/availability/actions/reducer';
import * as jobs from '../features/jobs/actions/reducer';

const initialState = merge(
  { requests: {} },
  common.initialState,
  availability.initialState,
  jobs.initialState,
);

const reducers = [
  ...common.reducers,
  ...availability.reducers,
  ...jobs.reducers,
];

export const reducer = generate.rootReducer(initialState, reducers);
