import { generate, request } from '@moved/services';

export const { action, reducer } = generate.redux({
  namespace: 'JOBS_GET_JOB_OFFER',
  request: (token) => request.get(`/vendors/job-offers/${token}`).then(r => r.data.data.vendor_job_offer),
  reducers: {
    success: (state, { response: jobOffer, params: [token] }) => {
      return {
        ...state,
        jobOffers: {
          ...state.jobOffers,
          [token]: jobOffer,
        },
      };
    },
    failure: (state, { response: error, params: [token] }) => {
      return {
        ...state,
        jobOffers: {
          ...state.jobOffers,
          [token]: null,
        },
      };
    },
  }
});

export const initialState = {
  jobOffers: {},
};
