import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, merge } from 'lodash';
import moment from 'moment';
import * as Yup from 'yup';

import { useModal, useNotify } from '@moved/services';
import { DynamicForm } from '@moved/ui';

import { finalizeJob } from '../actions';
import { CardDeclinedModal } from './CardDeclinedModal';

import CSS from '../styles/FinalizeJobModal.module.scss';

export const FinalizeJobModal = ({ job, ...props }) => {
  // REDUX
  const vendor = useSelector(state => state.vendor);

  if(!vendor.has_stripe) return (<StripeConnectModal/>);
  return (
    job.is_hourly ? (
      <HourlyModal job={job} vendor={vendor} {...props}/>
    ) : (
      <FlatRateModal job={job} vendor={vendor} {...props}/>
    )
  );
};

const StripeConnectModal = (props) => {
  // HOOKS
  const Modal = useModal();

  // STATE
  const [pending, setPending] = useState(false);

  // HELPERS
  const {
    REACT_APP_STRIPE_CLIENT_ID: stripeClientId,
    REACT_APP_BASE_URL: appUrl,
  } = process.env;
  const stripeLink = `https://connect.stripe.com/oauth/authorize?response_type=code&scope=read_write` +
    `&client_id=${stripeClientId}&redirect_uri=${appUrl}/stripe_connect`;

  return (
    <div>
      <h3 className={'txt-center mb-20'}>Finalize Delivery</h3>
      <p>
        Before you can finalize and get paid for this move,<br/>
        you&rsquo;ll need to set up a Stripe account.
      </p>
      <div className={'mt-30 flex justify-center'}>
        <a className={'btn-primary btn--full' + (pending ? ' loading' : '')} href={stripeLink}
         onClick={() => setPending(true)}>Set Up Stripe</a>
        <button className={'btn-gray btn--full mt-10'} onClick={() => Modal.close(false)}>Later</button>
      </div>
    </div>
  );
};

const HourlyModal = ({job, ...props}) => {
  // STATE
  const [startEnd, setStartEnd] = useState();
  const [values, setValues] = useState({});

  // CONVENIENCE FUNCTIONS
  const timeChange = (option, setFieldValue, name) => {
    let start_time, end_time;
    if(name === 'start_time') {
      end_time = values.end_time;
      start_time = option.value;
      const startEnd = moment(start_time,'h:mma').add({minutes: 15}).format('h:mma');
      setStartEnd(startEnd);
      if(moment(end_time, 'h:mma').isBefore(moment(startEnd, 'h:mma'))) {
        setFieldValue('end_time',startEnd,true);
        end_time = startEnd;
      }
    }
    else if(name === 'end_time') {
      start_time = values.start_time;
      end_time = option.value;
    }
    updateTotalHours(start_time, end_time, setFieldValue);
  };
  const updateTotalHours = (start_time, end_time, setFieldValue) => {
    let totalTime = 0;
    if(start_time && end_time)
      totalTime = moment.duration(moment(end_time,'h:mma').diff(moment(start_time,'h:mma'))).as('hours');
    return setFieldValue('total_hours',totalTime,true);
  };
  const updateTotalPrice = (prices, setFieldValue) => {
    let sum = 0;
    if(prices.total_hours && prices.hourly_rate)
      sum = prices.total_hours * prices.hourly_rate;
    prices.additional_fees.forEach((row) => {
      if(row.amount) sum += Number(row.amount);
    });
    setFieldValue('total',sum,true);
  };

  // MODAL CONFIGURATION
  const validation = Yup.object().shape({
    additional_fees: Yup.array().of(
      Yup.object().shape({
        description: Yup.string().required("Required"),
        amount: Yup.string().required("Required"),
      })
    ),
    total: Yup.number().moreThan(0,'Must be a valid total.'),
  });
  const fields = [
    {
      label: 'Start Time',
      name: 'start_time',
      type: 'timePicker',
      value: '',
      min: '5:00am',
      max: '10:00pm',
      required: true,
      onChange: timeChange,
      half: true,
    },
    {
      label: 'End Time',
      name: 'end_time',
      type: 'timePicker',
      value: '',
      min: startEnd || '5:15am',
      max: '11:00pm',
      required: true,
      onChange: timeChange,
      half: true,
    },
    {
      label: 'Total Hours',
      name: 'total_hours',
      type: 'text',
      value: 0,
      readOnly: true,
    },
    {
      label: 'Hourly Rate',
      name: 'hourly_rate',
      type: 'currency',
      value: job.rate,
      readOnly: true,
    },
    {
      label: 'Additional Fees',
      name: 'additional_fees',
      type: 'array',
      value: [],
      fieldInfo: {
        description: {
          label: 'Description',
          type: 'text',
        },
        amount: {
          label: 'Amount',
          type: 'currency',
        },
      },
    },
    {
      label: 'Final Price',
      name: 'total',
      type: 'currency',
      value: 0,
      readOnly: true
    },
  ];

  // FORM HANDLERS
  const handleChange = ({ total, ...values }, { setFieldValue }) => {
    updateTotalPrice(values,setFieldValue);
    setValues(values);
  };

  return (
    <FinalizeModal
      job={job}
      fields={fields}
      validation={validation}
      onChange={handleChange}
      {...props}
    />
  );
};

const FlatRateModal = ({job, ...props}) => {
  // MODAL CONFIGURATION
  const validation = Yup.object().shape({
    total: Yup.number().moreThan(0,'Must be a valid total.'),
  });
  const defaultPriceBreakdown = {
    volume_price: 0,
    travel_price: 0,
    stairs_price: 0,
    inventory_price: 0,
    storage_price: 0,
    packing_price: 0,
    packing_unpacking_price: 0,
  };
  const priceBreakdown = merge(defaultPriceBreakdown, get(job,'price_breakdown'));
  const packingField = get(job,'packing_option') === 'packing_unpacking' ? {
    label: 'Packing & Unpacking',
    name: 'packing_unpacking_price',
    type: 'currency',
    value: priceBreakdown.packing_unpacking_price,
  } : {
    label: 'Packing',
    name: 'packing_price',
    type: 'currency',
    value: get(job,'packing_option') === 'packing' ? priceBreakdown.packing_price : 0,
  };
  const fields = [
    { label: 'Volume', type: 'currency', name: 'volume_price', value: priceBreakdown.volume_price || get(job,'total') },
    { label: 'Travel & Tolls', type: 'currency', name: 'travel_price', value: priceBreakdown.travel_price },
    { label: 'Stairs', type: 'currency', name: 'stairs_price', value: priceBreakdown.stairs_price },
    { label: 'Special Items', type: 'currency', name: 'inventory_price', value: priceBreakdown.inventory_price },
    { label: 'Storage', type: 'currency', name: 'storage_price', value: priceBreakdown.storage_price },
    packingField,
    { label: 'Final Price', type: 'currency', name: 'total', value: get(job,'total') || 0, readOnly: true, required:true },
  ];

  // CONVENIENCE FUNCTIONS
  const updateTotalPrice = (prices, setFieldValue) => {
    let sum = 0;
    for (const field in prices) sum += Number(prices[field]);
    setFieldValue('total',sum,true);
  };

  const handleChange = ({ total, ...values },{ setFieldValue }) => {
    updateTotalPrice(values,setFieldValue);
  };

  return (
    <FinalizeModal
      job={job}
      fields={fields}
      validation={validation}
      onChange={handleChange}
      {...props}
    />
  );
};

const FinalizeModal = ({job, vendor, ...formProps}) => {
  // HOOKS
  const Modal = useModal();
  const Notify = useNotify();
  const dispatch = useDispatch();

  // REDUX
  const pending = useSelector(state => state.requests.JOBS_FINALIZE_JOB_PENDING);

  // HELPERS
  const handleSubmit = ({ total, hourly_rate, ...prices }) => {
    if(pending) return;
    let finalizeData, priceData;
    // set the price_breakdown
    if(job.app === 'direct') {
      finalizeData = {
        total
      };
      priceData = {
        price_breakdown: {
          ...prices,
          final_price: total,
        }
      };
      if(job.is_hourly) {
        finalizeData.total_hours = prices.total_hours;
      } else {
        priceData.packing_option = job.packing_option ||
          (prices.packing_price > 0 ? 'packing' : 'none')
      }

    } else {
      finalizeData = {
        total,
        ...prices,
      };
    }
    dispatch(finalizeJob(job, finalizeData, priceData))
      .then(() => {
        Notify.default('Job successfully completed!');
        Modal.close(true);
      })
      .catch(error => {
        if(
          // Handle DA stripe payment errors
          (get(error,'response.data.status') === 400 && get(error,'response.data.message.error_code') === 'card_declined') ||
          // Handle RA/SA stripe payment errors
          (get(error,'response.data.errors',[]).find(error => error.code === 'stripe-generic-error'))
        ) {
          Modal.close(true);
          Modal.open(<CardDeclinedModal/>);
        }
        else {
          Notify.error();
        }
      });
  };

  return (
    <div className={CSS.finalize}>
      <h3 className='txt-center mb-20'>Finalize Delivery</h3>
      <p className='txt-center'>Once the job has been completed, please review the final charges with the customer. Enter the final amount below and click &ldquo;Finish Job&rdquo;.</p>
      <p className='txt-center'>Note: this will immediately charge the customer&rsquo;s card.<br />Make sure the amount is correct.</p>
      <div className='flex items-center flex-column'>
        <DynamicForm
          id="price-form"
          formStyle={'stacked'}
          className={CSS.finalize_form}
          onSubmit={handleSubmit}
          {...formProps}
        />
      </div>
      <div className='mt-30 flex justify-center'>
        <button form="price-form" type="submit"
          className={'btn-primary' + (pending  ? ' loading' : '')}
          disabled={pending}>
            Finish Job
        </button>
      </div>
    </div>
  );
};
