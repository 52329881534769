import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { useModal, useNotify } from '@moved/services';
import { LoaderOverlay } from '@moved/ui';

import { JobModal } from './JobModal';
import { getJobDetails } from '../actions';

export const ActiveJob = ({parent, match}) => {
  const { active } = useParams();
  const history = useHistory();
  const Modal = useModal();
  const Notify = useNotify();
  const dispatch = useDispatch();

  // redux
  const pending = useSelector(state => state.requests.JOBS_GET_JOB_DETAILS_PENDING);

  useEffect(() => {
    dispatch(getJobDetails(active))
      .then(job =>
        Modal.open(<JobModal job={job} />, {
          onClose: (result) => history.push(parent),
        })
      )
      .catch(() => Notify.error(`We're sorry, we were unable to fetch information about that job.`));
  // eslint-disable-next-line
  },[active]);

  return pending ? (<LoaderOverlay/>) : null;
};
