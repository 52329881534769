import { merge } from 'lodash';

import * as getAvailabilityCalendar from './getAvailabilityCalendar';
import * as saveSlot from './saveSlot';
import * as updateActiveDate from './updateActiveDate';

export const initialState = merge(
  getAvailabilityCalendar.initialState,
  updateActiveDate.initialState,
  saveSlot.initialState,
);

export const reducers = [
  getAvailabilityCalendar.reducer,
  saveSlot.reducer,
  updateActiveDate.reducer,
];
