import React from 'react';

import { LayoutStandard } from '../../common';

export const CalendarFailure = () => {
  return (
    <LayoutStandard>
      <section className='mt-50'>
        <div className="container">
          <h5 className='mb-30'>Please try again.</h5>
          <p>We're sorry, it appears something went wrong.  Please check your URL and try again.</p>
          <p>We apologize for the inconvenience.</p>
        </div>
      </section>
    </LayoutStandard>
  );
};
