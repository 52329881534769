import { merge } from 'lodash';

import * as getVendor from './getVendor';
import * as stripeConnectVendor from './stripeConnectVendor';

export const initialState = merge(
  getVendor.initialState,
);

export const reducers = [
  getVendor.reducer,
  stripeConnectVendor.reducer,
];
