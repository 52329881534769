import React from 'react';
import classNames from 'classnames';

import CSS from './Hamburger.module.scss';

export const HamburgerMenu = ({ isActive, theme='spin', className, onClick }) => (
  <div
    className={classNames(
      CSS['hamburger'],
      CSS[`hamburger--${theme}`],
      className,
      {[CSS['is-active']]:isActive},
    )}
    onClick={onClick}
  >
    <div className={CSS['hamburger-box']}>
      <div className={CSS['hamburger-inner']}></div>
    </div>
  </div>
)
