import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';

import { useNotify, format } from '@moved/services';
import { DynamicForm } from '@moved/ui';

import { respondJobOffer } from '../actions';
import CSS from '../styles/ConfirmJob.module.scss';

export const AcceptJob = () => {
  const { token } = useParams();
  const history = useHistory();
  const Notify = useNotify();
  const dispatch = useDispatch();

  // redux
  const pending = useSelector(state => state.requests.JOBS_RESPOND_JOB_OFFER_PENDING);
  const jobOffer = useSelector(state => state.jobOffers[token]);

  // state
  const [startEnd, setStartEnd] = useState();
  const [values, setValues] = useState({});

  // re-route if this action is not available
  useEffect(() => {
    if(!jobOffer || pending) return;
    if(jobOffer.status !== 'pending' || moment.utc(jobOffer.expires_at).isBefore()) {
      history.replace(`/confirm-job/${token}`);
    }
  // eslint-disable-next-line
  },[token,jobOffer,pending]);

  if(!jobOffer) return null;

  // CONVENIENCE FUNCTIONS
  const timeChange = (option, setFieldValue, name) => {
    let earliest, latest;
    if(name === 'earliest_arrival_time') {
      latest = values.latest_arrival_time;
      earliest = option.value;
      const startEnd = moment(earliest,'h:mma').add({hour: 1}).format('h:mma');
      setStartEnd(startEnd);
      if(moment(latest, 'h:mma').isBefore(moment(startEnd, 'h:mma'))) {
        setFieldValue('latest_arrival_time',startEnd,true);
      }
    }
  };

  // FORM
  const formFields = [
    {
      placeholder: 'From',
      name: 'earliest_arrival_time',
      type: 'timePicker',
      value: '',
      min: '5:00am',
      max: '10:00pm',
      interval: ['00'],
      required: true,
      onChange: timeChange,
      half: true,
    },
    {
      placeholder: 'To',
      name: 'latest_arrival_time',
      type: 'timePicker',
      value: '',
      min: startEnd || '6:00am',
      max: '11:00pm',
      interval: ['00'],
      required: true,
      onChange: timeChange,
      half: true,
    },
  ];
  const isValid = values.earliest_arrival_time && values.latest_arrival_time;
  const handleSubmit = ({earliest_arrival_time, latest_arrival_time}) => {
    if(pending) return;
    // format the data for serverside
    const data = {
      earliest_arrival_time:moment(earliest_arrival_time,'h:mma').format('HH:mm:ss'),
      latest_arrival_time: moment(latest_arrival_time,'h:mma').format('HH:mm:ss'),
    };
    dispatch(respondJobOffer(token,'accept',data))
      .catch(error => Notify.error(format.error(error)));
  };

  return (
    <>
    <h3 className={CSS.list_title}>Arrival window</h3>
    <div className='flex items-center flex-column'>
      <DynamicForm
        id="confirmation-form"
        formStyle={'stacked'}
        className={CSS.form}
        fields={formFields}
        onChange={setValues}
        onSubmit={handleSubmit}
      />
    </div>
    <div className='mt-30 flex justify-center'>
      <button form="confirmation-form" type="submit"
        className={'btn-primary btn--full' + (pending  ? ' loading' : '')}
        disabled={pending || !isValid}>
          Accept Job
      </button>
    </div>
    </>
  );

};
