import React from 'react';

import { useModal } from '@moved/services';
import { CustomerSupportEmailLink } from '@moved/product';

export const CardDeclinedModal = ({job, ...props}) => {
  const Modal = useModal();
  return (
    <div>
      <h3 className={'txt-center mb-20'}>Thank you for completing the job!</h3>
      <p>
        You are all set on your end, but please note: the customer's card on{' '}
        file needs to be updated. Don't worry - Moved is on the case and you{' '}
        can expect a resolution typically in 24-48 hours. Email us at{' '}
        <CustomerSupportEmailLink /> with any questions!
      </p>
      <div className={'flex justify-center mt-30'}>
        <button className="btn-primary" onClick={() => Modal.close()}>Confirm</button>
      </div>
    </div>
  );
};
