import { generate, request } from '@moved/services';

export const { action, reducer } = generate.redux({
  namespace: 'COMMON_STRIPE_CONNECT_VENDOR',
  request: (id,data) => request.post(`/vendors/${id}/onboard`, data),
  reducers: {
    success: (state) => {
      return {
        ...state,
        vendor: {
          ...state.vendor,
          has_stripe: true,
        }
      };
    },
  }
});
