import React from 'react';
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import { isEmpty, get } from 'lodash';
import classNames from 'classnames';

import { format } from '@moved/services';

import { JobDetailsList } from './JobDetailsList';
import { FieldList } from './FieldList';

import CSS from '../styles/JobModal.module.scss';

export const JobModal = ({ job={} }) => {
  // state
  const history = useHistory();

  // helper functions
  const markDelivered = (e) => {
    if(e) e.stopPropagation();
    // TODO: once we have a v0 get vendor endpoint we need to handle the stripeConnect modal (which also doesn't exist in react yet)
    // if(!vendor.has_stripe) return Payment.modals.stripeConnect(this.vendor, true);
    history.push(`${history.location.pathname}/finalize`);
  };

  let fields = [
    // Top Level Job Details
    {
      label: 'Job Number',
      value: job.job_number,
    },
    {
      label: 'Pickup Date',
      value: (<Moment date={job.service_date} format='M/DD/YYYY'/>),
    },
    {
      label: 'Arrival Window',
      value:  !isEmpty(job.earliest_arrival_time) && !isEmpty(job.latest_arrival_time) ? `${job.earliest_arrival_time} - ${job.latest_arrival_time}` : get(job,'mover_booking.time_preference'),
    },
    {
      label: 'Crew Size',
      value:  job.crew_size && `${job.crew_size} movers`,
    },

    // Customer Personal Data
    {
      label: 'Phone Number',
      value: format.phone(get(job,'mover_booking.contact_phone') || get(job,'contact_phone') || get(job, 'customer.phone')),
    },
    {
      label: 'Email Address',
      value: get(job,'mover_booking.contact_email') || get(job,'contact_email') || get(job, 'customer.email'),
    },

    // Hourly or Flat Rate Prices
    job.coupon_total && {
      label: 'Partner Coupon',
      value: format.currency(job.coupon_total),
    },

    // Hourly Pricing Fields
    job.is_hourly && job.status === 'Delivered' && {
      label: 'Customer Total',
      value: (job.final_price || job.total) ? format.currency((job.final_price || job.total)) : '&mdash;'
    },
    job.is_hourly && job.status === 'Delivered' && {
      label: 'Hourly Rate',
      value: get(job,'price_breakdown.hourly_rate') && format.currency(get(job,'price_breakdown.hourly_rate', 0)),
    },
    job.is_hourly && job.status === 'Delivered' && {
      label: 'Number of Hours',
      value: job.total_hours,
    },
    job.is_hourly && job.status !== 'Delivered' && {
      label: 'Customer Rate',
      value: job.rate ? `${format.currency(job.rate)}/hour` : '&mdash;',
    },
    job.is_hourly && job.status !== 'Delivered' && {
      label: 'Customer Minimum',
      value:  job.minimum && format.currency(job.minimum),
    },
    job.is_hourly && {
      label: job.status === 'Delivered' ? 'Revenue' : 'Revenue Estimate',
      value: job.revenue ? format.currency(job.revenue) : 'TBD',
    },
    job.is_hourly && {
      label: 'Additional Fees',
      value: get(job,'price_breakdown.additional_fees.length') &&
        format.currency(get(job,'price_breakdown.additional_fees',[]).reduce((sum,fee) => sum + (fee.amount || 0), 0)),
    },

    // Flat Rate Pricing Fields
    !job.is_hourly && job.status === 'Delivered' && {
      label: 'Customer Total',
      value: (job.final_price || job.total) && format.currency((job.final_price || job.total)),
    },
    !job.is_hourly && job.status !== 'Delivered' && {
      label: 'Customer Estimate',
      value: (job.estimated_price || job.total) && format.currency((job.estimated_price || job.total)),
    },
    !job.is_hourly && {
      label: job.status === 'Delivered' ? 'Revenue' : 'Revenue Estimate',
      value: job.revenue ? format.currency(job.revenue) : 'TBD',
    },
    !job.is_hourly && {
      label: 'Volume',
      value: get(job,'price_breakdown.volume_price') && format.currency(get(job,'price_breakdown.volume_price', 0)),
    },
    !job.is_hourly && {
      label: 'Travel & Tolls',
      value: get(job,'price_breakdown.travel_price') && format.currency(get(job,'price_breakdown.travel_price', 0)),
    },
    !job.is_hourly && {
      label: 'Stairs',
      value: get(job,'price_breakdown.stairs_price') && format.currency(get(job,'price_breakdown.stairs_price', 0)),
    },
    !job.is_hourly && {
      label: 'Storage',
      value: get(job,'price_breakdown.storage_price') && format.currency(get(job,'price_breakdown.storage_price', 0)),
    },
    !job.is_hourly && {
      label: 'Special Items',
      value: get(job,'price_breakdown.inventory_price') && format.currency(get(job,'price_breakdown.inventory_price', 0)),
    },
    !job.is_hourly && {
      label: 'Packing',
      value: (job.packing_option === 'packing') && get(job,'price_breakdown.packing_price') && format.currency(get(job,'price_breakdown.packing_price', 0)),
    },
    !job.is_hourly && {
      label: 'Packing & Unpacking',
      value: (job.packing_option === 'packing_unpacking') && get(job,'price_breakdown.packing_unpacking_price') && format.currency(get(job,'price_breakdown.packing_unpacking_price', 0)),
    },

  ].filter(f => f); // remove the conditionally filtered items

  return (
    <div className={CSS.job_modal}>
      <h3 className='txt-center mb-10'>{get(job,'customer.firstname')} {get(job,'customer.lastname')}</h3>
      <div className='txt-center mb-30'>
        <span className={classNames(CSS.status_pill, CSS[format.safeClass(job.status)])}>{ job.status }</span>
      </div>

      <div>
        <FieldList fields={fields}/>
      </div>

      <div className={'mt-30'}>
        <JobDetailsList job={job}/>
      </div>

      {job.status === 'In Transit' && (
        <div className='flex mt-30'>
          <button className={classNames('btn-primary','btn--full',CSS.deliver_button)} onClick={markDelivered}>Mark as Delivered</button>
        </div>
      )}

    </div>
  );
}
