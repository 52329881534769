import React from 'react';
import { noop } from 'lodash';
import classNames from 'classnames';
import { Icon } from '../../../sondheim/components/Icon';

import sortTableCSS from './SortTable.module.scss';

export const SortTable = ({ columns, rows, activeSort, updateSort, rowClick, className }) => {
  return (
    <table className={classNames(sortTableCSS.sort_table,className || '')}>
      <thead>
        <tr>
          { columns.filter(column => column.display !== false).map(column => (
            <th key={column.name} className={column.class}>
              <div onClick={() => (column.sortable && updateSort) ? updateSort(column): noop()}
                className={classNames(sortTableCSS.column_title, {
                  [sortTableCSS.active]: activeSort.column === column.name,
                  [sortTableCSS.sortable]: column.sortable && updateSort
                })}>
                <span className={sortTableCSS.sort_name}>{column.label}</span>
                { column.sortable && activeSort.column === column.name && (
                  <span className={sortTableCSS.sort_arrow}>
                    { activeSort.direction === (column.direction || false) ? (
                      <Icon symbol='Chevron-down' library='navigation' size='16px' />
                    ) : (
                      <Icon symbol='Chevron-up' library='navigation' size='16px' />
                    )}
                  </span>
                )}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        { rows.map(row => (
          <tr key={row.id}
            className={classNames({[sortTableCSS.row_click]: rowClick})}
            onClick={() => rowClick ? rowClick(row) : noop()}>
            { columns.filter(column => column.display !== false).map((column,index) => (
              <td key={column.name}>{ column.value(row) }</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );

};
