import React from 'react';
import { Four04 } from '@moved/product';

import { LayoutStandard } from './LayoutStandard';

export const PageNotFound = () => {
  return (
    <LayoutStandard>
      <Four04 />
    </LayoutStandard>
  );
}
