import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment'
import Moment from 'react-moment';
import { get } from 'lodash';

import { Icon } from '@moved/ui';

import jobCSS from '../styles/UpcomingJobs.module.scss';

export const UpcomingJobs = React.memo(({ jobDates, activeDate, onSelect, preferences }) => {
  // UTILITIES
  const history = useHistory();

  // CONVENIENCE FUNCTIONS
  const showJobModal = (id) => history.push(`/calendar/jobs/${id}`);

  const _renderJobs = (jobs) => {
    return jobs.map(job => (
      <div key={get(job,'vendor_job.id')} className={jobCSS.job} onClick={(e) => showJobModal(get(job,'vendor_job.id'))}>{get(job,'customer.fullname')}</div>
    ));
  };

  const _renderSlots = (slots) => {
    return Object.keys(slots).map(index => (
      <div className={jobCSS.slot} key={index}>
        <h6 className={jobCSS.slot_header}>
          <span className={jobCSS.slot_label}>{preferences.slots[index].label}</span>
          <span className={jobCSS.slot_time}><Moment date={preferences.slots[index].start_time} format='h A' /></span>
        </h6>
        {_renderJobs(slots[index])}
        <div className={jobCSS.spacer} />
        <h6 className={jobCSS.slot_footer}>
          <span className={jobCSS.slot_time}><Moment date={preferences.slots[index].end_time} format='h A' /></span>
        </h6>
      </div>
    ));
  };

  const _renderJobDates = (dates) => {
    if (Object.keys(dates).length === 0)
      return (
        <p className={jobCSS.no_jobs}>
          There are no jobs currently scheduled for the month of{' '}
          <Moment date={activeDate} format='MMMM YYYY' />.
        </p>
      );
    return Object.keys(dates).map(date => (
      <div className={jobCSS.day} key={date}>
        <h4 className={jobCSS.day_header}>
          <span className={jobCSS.day_date}>
          <span><Moment date={date} format='dddd' /></span> <Moment date={date} format='MMM Do' />
          </span>
          <span className={jobCSS.day_icon} onClick={(e) => { return onSelect(moment(date)); }}>
            <Icon symbol="Date" library="code" />
          </span>
        </h4>
        {_renderSlots(dates[date])}
      </div>
    ));
  }

  return (
    <div className={jobCSS.container}>
      <div className={jobCSS.jobs_list}>

      {_renderJobDates(jobDates)}

      </div>
    </div>
  );

});
