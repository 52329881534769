import React from 'react';

import { Icon } from '@moved/ui';

import CSS from '../styles/NoResults.module.scss';

export const NoResults = ({ message }) => {
  return (
    <div className={CSS['no-results']}>
      <div className={CSS['icon']}>
        <Icon symbol="Sad" library="general" size='50px' />
      </div>
      <div>{ message }</div>
    </div>
  );
};
