import { generate, request } from '@moved/services';
import { get } from 'lodash';

export const { action, reducer } = generate.redux({
  namespace: 'JOBS_FINALIZE_JOB',
  request: (job, finalizeData, priceData) => {
    // TODO: clean this up after v0 handles direct app jobs
    if(job.app === 'direct') {
      finalizeData.charge_card = true;
      return request.patch(`/v2/move_transactions/${job.move_transaction_id}`, { data: priceData }) // update the price breakdown (must be first for receipt to show correctly)
          .then(() => request.post(`/v2/move_transactions/${job.move_transaction_id}/finalize`, finalizeData)) // charge the card and other finalization handling
          .then(() => request.get(`/vendors/jobs/${job.id}`)) // refresh the job data
          .then(r => r.data.data.vendor_job);
    } else {
      return request.post(`/vendors/jobs/${job.id}/finalize`, finalizeData).then(r => r.data.data.vendor_job);
    }
  },
  reducers: {
    success: (state, { response: job }) => {
      // Find and update the status if this job is also in the active results list
      let activeSet = get(state,'jobs.activeSet',[]);
      activeSet = activeSet.map(item => {
        if(item.id === job.id) return {
          ...item,
          status: job.status,
          total: job.total,
          final_price: job.final_price,
          revenue: job.revenue,
        }
        else return item;
      });

      return {
        ...state,
        jobs: {
          ...state.jobs,
          activeSet: activeSet,
        },
        jobDetails: {
          ...state.jobDetails,
          [job.id]: job,
        },
      }
    },
  },
});
