import React from 'react';

import { AtomSpinner } from '@moved/ui';
import { LayoutStandard } from '../../common'

export const CalendarPending = () => {
  return (
    <LayoutStandard>
      <div className="container flex flex-column">
        <div className='mt-50'>
          <AtomSpinner size={70} />
          <p className='mt-20 italic'>One moment while we fetch your calendar&hellip;</p>
        </div>
      </div>
    </LayoutStandard>
  );
};
