import React from 'react';
import Moment from 'react-moment';

import weekCSS from '../../styles/WeeklyCalendar.module.scss';

export const AvailabilityKey = (props) => {

  const { slots } = props.preferences;

  return (
    <div className={weekCSS.key_column}>
      <div className={weekCSS.key_header} />

      { slots.map(slot => (
        <div key={`${slot.date}-${slot.label}`} className={weekCSS.key_slot}>
          <div className={weekCSS.key_slot_content}>
            <label>{slot.label}</label>
            <label className={weekCSS.key_times}><Moment date={slot.start_time} format='ha' />-<Moment date={slot.end_time} format='ha' /></label>
          </div>
        </div>
      ))}

    </div>
  );
}
