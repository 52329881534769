import { generate, request } from '@moved/services';

export const { action, reducer } = generate.redux({
  namespace: 'JOBS_RESPOND_JOB_OFFER',
  request: (token, action, data) => request.post(`/vendors/job-offers/${token}/${action}`, data).then(r => r.data.data.vendor_job_offer),
  reducers: {
    success: (state, { response: jobOffer, params: [token] }) => {
      return {
        ...state,
        jobOffers: {
          ...state.jobOffers,
          [token]: jobOffer,
        },
      };
    }
  }
});

export const initialState = {
  jobOffers: {},
};
