import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'JOBS_GET_JOB_DETAILS',
  request: (jobID) => request.get(`/vendors/jobs/${jobID}`).then(r => r.data.data.vendor_job),
  selector: (state, jobID) => get(state, `jobDetails.${jobID}`),
  cache: { minutes: 1 },
  reducers: {
    success: (state, { response: job }) => {
      return {
        ...state,
        jobDetails: {
          ...state.jobDetails,
          [job.id]: job,
        },
      }
    }
  }
};

export const { action, reducer } = generate.redux(creator);

export const initialState = {
  jobDetails: {}
};
