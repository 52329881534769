import React from 'react';

import CSS from '../styles/FieldList.module.scss';

export const FieldList = ({fields=[]}) => {
  return fields.filter(field => field.value || field.count).map(field => (
    <div key={field.label} className={CSS.row}>
      <span className={CSS.row_label}>{field.label}</span>
      <span className={CSS.row_line} />
      <span className={CSS.row_value}>{ field.value || field.count }</span>
      { field.disclaimer && (
        <span className={CSS.row_disclaimer}>{ field.disclaimer }</span>
      )}
    </div>
  ));
};
