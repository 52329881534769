import { DashboardRedirect, DefaultPageNotFound } from '@moved/product';

import { PageNotFound } from './components/PageNotFound';
import { SigninRedirect } from './components/SigninRedirect';
import { StripeConnectRouter } from './components/StripeConnectRouter';

const routes = [
  // this route is for handling stripe connect link-backs after finishing onboarding
  {
    path: '/stripe_connect',
    name: 'Stripe Connect',
    component: StripeConnectRouter,
  },
  // vendor app doesn't have it's own login screen, so redirect to consumer-app signin
  {
    path: '/signin',
    name: 'Signin Redirect',
    component: SigninRedirect,
    restrict: ['guest'],
    exact: true,
  },
  // if logged in as vendor or guest base domain redirects to dashboard
  {
    path: '/',
    name: 'Dashboard Redirect',
    component: DashboardRedirect,
    allowGuest: true,
    exact: true,
  },
  // catchall route for logged in vendor is 404 with navigation
  {
    path: '*',
    name: 'Page not found',
    component: PageNotFound,
  },
  // catchall route for guests is shared 404 screen
  {
    path: '*',
    name: 'Page not found',
    component: DefaultPageNotFound,
    restrict: ['guest'],
  },
  // catchall route for logged in non-vendor is redirect to dashboard
  {
    path: '*',
    name: 'Dashboard Redirect',
    component: DashboardRedirect,
    restrict: ['customer','cx','client'],
  },
];

export default routes;
