import React from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty, get } from 'lodash';
import classNames from 'classnames';

import { useModal } from '@moved/services';
import { PopOver } from '@moved/ui';

import { SlotEdit } from '../../';
import weekCSS from '../../styles/WeeklyCalendar.module.scss';

const isPositive = (num) => Math.sign(num) >= 0;

const PriceMini = ({slot}) => {
  if(slot.price === 0) return null;
  let percent = Math.floor(slot.price * 100);
  let signClass = isPositive(percent) ? weekCSS.price_positive : weekCSS.price_negative;
  return (
    <div className={classNames(weekCSS.price,signClass)}>
      <span>{percent}&#37;</span>
    </div>
  );
}

const Price = ({slot}) => {
  if(slot.price === 0) return null;
  let percent = Math.floor(slot.price * 100);
  let signClass = isPositive(percent) ? weekCSS.price_positive : weekCSS.price_negative;
  let priceText = isPositive(percent) ? 'Premium' : 'Discount';

  return (
    <div className={classNames(weekCSS.price_large, signClass)}>
      <span className={weekCSS.price_num}>{percent}&#37;</span>
      <span className={weekCSS.price_txt}>{priceText}</span>
    </div>
  );
}

const RemainingTrucks = ({slot, jobs}) => {
  const numJobs = isEmpty(jobs) ? 0 : Object.keys(jobs).length;
  const numOpen = slot.capacity - numJobs;

  return slot.available && (
    <div className={weekCSS.remaining_trucks}>
      { numOpen === 0 ? (
        <>No trucks available</>
      ) : (
        <>
          <span>{numOpen}</span>&nbsp;
          {numOpen === 1 ? 'truck' : 'trucks'} available
        </>
      )}
    </div>
  );
}

const Jobs = ({jobs}) => {
  const history = useHistory();
  const showJobModal = (e,id) => {
    e.stopPropagation(); // prevent triggering click on slot
    history.push(`/calendar/jobs/${id}`);
  };
  return !isEmpty(jobs) && (
    <div className={weekCSS.jobs}>
      { jobs.map(job => (
        <div key={get(job,'vendor_job.id')} className={weekCSS.job} onClick={(e) => showJobModal(e,get(job,'vendor_job.id'))}>{get(job,'customer.fullname')}</div>
      ))}
    </div>
  );
};

const Availability = ({slot, jobs}) => {
  const numJobs = isEmpty(jobs) ? 0 : Object.keys(jobs).length;
  let filledCapacity = Math.floor((numJobs/slot.capacity) * 100);
  return (
    <div className={weekCSS.capacity}>
      <div className={weekCSS.capacity_txt}>
        <div className='flex-auto'><span>{numJobs}</span>&nbsp;&#47;&nbsp;<span>{slot.capacity}</span>&nbsp;&nbsp;jobs booked</div>
      </div>
      <div className={weekCSS.capacity_bar}>
        <div className={weekCSS.capacity_bar_fill} style={{width: filledCapacity+'%'}} />
      </div>
    </div>
  );
}

const SlotContent = ({slot, jobs, view}) => {
  switch(view) {
    case 'all':
      return (
        <>
          <PriceMini slot={slot}/>
          <Jobs jobs={jobs}/>
          <Availability slot={slot} jobs={jobs}/>
        </>
      );
    case 'availability':
      return (
        <>
          <RemainingTrucks slot={slot} jobs={jobs}/>
          <Availability slot={slot} jobs={jobs}/>
        </>
      );
    case 'jobs':
      return (<Jobs jobs={jobs}/>);
    case 'pricing':
      return (<Price slot={slot}/>);
    default:
      return null;
  }
};

export const AvailabilitySlot = ({ slot, view, jobs, modalEdit }) => {
  const Modal = useModal();

  const slotClasses = classNames(
    weekCSS.slot,
    {[weekCSS.slot_unavailable]: ['availability','all'].includes(view) && !slot.available},
    {[weekCSS.slot_capacity]: ['availability','all'].includes(view)}
  );

  const numJobs = isEmpty(jobs) ? 0 : Object.keys(jobs).length;

  const showSlotModal = () => {
    Modal.open(<div style={{paddingTop:'40px'}}><SlotEdit slot={slot} jobs={numJobs}/></div>);
  };

  return (
    <div className={slotClasses}>
      {modalEdit ? (
        <div className={weekCSS.slot_content} onClick={showSlotModal}>
          <SlotContent view={view} slot={slot} jobs={jobs}/>
        </div>
      ) : (
        <PopOver
          placement="right"
          trigger="click"
          className={weekCSS.slot_content}
          mountToBody={true}
          tooltip={<SlotEdit slot={slot} jobs={numJobs} />}
        >
          <SlotContent view={view} slot={slot} jobs={jobs}/>
        </PopOver>
      )}
    </div>
  );
}
