import { generate } from '@moved/services';

export const { action, reducer } = generate.redux({
  namespace: 'AVAILABILITY_UPDATE_ACTIVE_DATE',
  request: (date) => new Promise((resolve) => setTimeout(resolve(date),0)),
  reducers: {
    success: (state, { response: date }) => {
      return {
        ...state,
        activeDate: date,
      };
    }
  }
});

export const initialState = {};
