import React, { useState } from 'react';
import classNames from 'classnames';
import { Collapsible } from '@moved/ui';

import { FieldList } from './FieldList';

import CSS from '../styles/InventoryList.module.scss';

export const ExpandingList = ({items, label}) => {
  const [expanded, setExpanded] = useState(false);
  if(!items || !items.length) return null;
  return (
    <>
      <h6 className={CSS.inventory_title} onClick={() => setExpanded(!expanded)}>
        { label }
        <span className={classNames(CSS.arrow, {[CSS.arrow_open]:expanded})} />
      </h6>
      <Collapsible open={expanded}>
        <div className={CSS.inventory_list}>
          <div className={CSS.inventory_content}>
            <FieldList fields={items} />
          </div>
        </div>
      </Collapsible>
    </>
  );
};
