import React from 'react';

import CSS from '../styles/AddressList.module.scss';

import { format } from '@moved/services'; // switch to accordion (keep in this file?)

export const AddressList = ({addresses=[]}) => {
  return addresses.map(address => (
    <div key={address.id} className={CSS.address_row}>

      { format.address(address) }

      { (address.unit || address.floor) && (
        <div className={CSS.address_items}>
          {address.unit && (
            <div className={CSS.address_item}>
              <h6>Unit</h6>
              <span>{address.unit}</span>
            </div>
          )}
          {address.floor && address.floor !== '0' && (
            <div className={CSS.address_item}>
              <h6>Floor</h6>
              <span>{address.floor}</span>
            </div>
          )}
          {(address.flights_of_stairs || address.has_elevator) && (
            <div className={CSS.address_item}>
              <h6>Elevator/Stairs</h6>
              <span>{address.has_elevator ? 'Elevator' : address.flights_of_stairs + ' Flights of Stairs'}</span>
            </div>
          )}
        </div>
      )}

    </div>
  ));
};
