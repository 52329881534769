import { Availability } from './components/Availability';
import { ActiveJob } from '../jobs/components/ActiveJob';
import { FinalizeJob } from '../jobs/components/FinalizeJob';

const routes = [
  {
    path: '/calendar',
    name: 'Availability',
    component: Availability,
    children: [{
      path: '/jobs/:active(\\d+)',
      name: 'Active Job',
      component: ActiveJob,
      exact: true,
    },{
      path: '/jobs/:active(\\d+)/finalize',
      name: 'Finalize Job',
      component: FinalizeJob,
      exact: true,
    }],
  }
];

export default routes;
