export const packing = (packingType) => {
  switch (packingType) {
    case 'none':
      return `None`;
    case 'packing_unpacking':
      return 'Packing & Unpacking';
    case 'packing':
      return 'Packing';
    default:
      return packingType;
  }
};
