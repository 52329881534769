import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '@moved/ui';

import CSS from '../styles/ProfileLink.module.scss';

export const ProfileLink = () => {
  const vendor = useSelector(state => state.vendor);

  return (
    <a className={CSS.profile_link} href={`${process.env.REACT_APP_MOVED_APP_URL}/account`}>
      <span className={CSS.label}>Profile</span>
      <span className={CSS.name}>{ vendor.name }</span>
      <span className={CSS.arrow}>
        <Icon className={CSS.icon} symbol='Chevron-down' library='navigation' alt='Profile' role='img'/>
      </span>
    </a>
  );
};
