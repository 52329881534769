import React, { useState } from 'react';
import moment from 'moment'
import Moment from 'react-moment';

import { MonthlyCalendar, Icon } from '@moved/ui';
import avaCSS from '../styles/Availability.module.scss';

import { AvailabilityDay } from '../'


//If calendar data is pending, don't rerender
const shouldSkipUpdate = (oldProps, newProps) => newProps.getCalendarPending;

export const MobileAvailability = React.memo((props) => {
  const { week, selected, view, jobs, selectDate } = props;

  const [calendarOpen, setCalendarOpen] = useState(false);

  const toggleCalendar = () => setCalendarOpen(!calendarOpen);

  const updateDate = (date) => {
    selectDate(date);
    toggleCalendar();
  };

  let currentDay = week.find(day => day.date === selected);

  return (
    <div className={avaCSS.mobile_column}>

      <div className={[avaCSS.mobile_top, calendarOpen && avaCSS.open].join(' ')}>
        <div className={avaCSS.mobile_calendar_header}>
          <h4><Moment date={selected} format='MMMM Do, YYYY' /></h4>
          <div>
            <div onClick={toggleCalendar} className={[avaCSS.mobile_btn, calendarOpen && avaCSS.mobile_btn_active].join(' ')}>
              {calendarOpen ? (
                <Icon symbol="Close" library="navigation" color="white" key='mobile_cal_close' />
              ) : (
                <Icon symbol="Date" library="code" color="black" key='mobile_cal_today' />
              )}
            </div>
          </div>
        </div>
        {calendarOpen && (
          <div className={avaCSS.mobile_calendar}>
            <MonthlyCalendar
              selected={selected}
              onSelect={updateDate}
              moment={moment(selected)}
            />
          </div>
        )}
      </div>

      <div className={avaCSS.mobile_top_spacer} />

      <AvailabilityDay
        key={currentDay.date}
        date={currentDay.date}
        jobs={jobs[currentDay.date]}
        appointments={currentDay.slots}
        active={true}
        view={view}
        modalEdit={true}
        navigation={true}
        selectDate={updateDate}
      />
    </div>
  );
}, shouldSkipUpdate);
