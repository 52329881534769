import React from 'react';
import { noop } from 'lodash';

import { HamburgerMenu } from '@moved/ui';

import mvd_logo from '../images/mvd-logo.svg';
import mvd_icon from '../images/mvd-icon.svg';
import CSS from '../styles/NavHeader.module.scss';

export const NavHeader = ({ leftContent, rightContent, menuOpen, menuAction = noop }) => {

  return (
    <header className={CSS.header}>
      <div className='flex items-center'>

        <div className={CSS.hamburger_section}>

          <div className={CSS.hamburger_hldr}>
            <HamburgerMenu isActive={menuOpen} onClick={menuAction} />
          </div>

        </div>
        <div className={CSS.logo_section}>
          <img src={mvd_logo} alt="Moved." className={CSS.mvd_logo} />
          <img src={mvd_icon} alt="Moved." className={CSS.mvd_icon} />
        </div>

        <div className={CSS.content_section}>
          {leftContent && (
            <div className={CSS.left_content}>
              {leftContent}
            </div>
          )}

          <div className='flex flex-auto justify-end'>
            {rightContent}
          </div>
        </div>

      </div>
    </header>
  );
};
