import React from 'react';

import mvd_logo from '../images/mvd-logo.svg';

import CSS from '../styles/LayoutLanding.module.scss';

export const LayoutLanding = ({ children }) => {
  return (
    <div className={CSS.template}>
      <header className={CSS.header}>
        <img src={mvd_logo} alt="Moved." className={CSS.logo} />
      </header>
      <div className={CSS.content}>
        {children}
      </div>
    </div>
  );
};
