import { merge } from 'lodash';

import * as getJobs from './getJobs';
import * as getJobDetails from './getJobDetails';
import * as finalizeJob from './finalizeJob';
import * as getJobOffer from './getJobOffer';
import * as respondJobOffer from './respondJobOffer';

export const reducers = [
  getJobs.reducer,
  getJobDetails.reducer,
  finalizeJob.reducer,
  getJobOffer.reducer,
  respondJobOffer.reducer,
];

export const initialState = merge(
  getJobs.initialState,
  getJobDetails.initialState,
  getJobOffer.initialState,
  respondJobOffer.initialState,
);
