import React from 'react';

import { format } from '@moved/services';

import { FieldList } from './FieldList';
import { AddressList } from './AddressList';
import { ExpandingList } from './ExpandingList';

export const JobDetailsList = ({ job={}, include={} }) => {
  const { mover_booking } = job;
  /* render helpers */
  const detailsFields = [
    {
      label: 'Move Size',
      value: mover_booking.number_of_rooms,
    },
    mover_booking.number_of_rooms === 'Storage' && {
      label: 'Storage Size',
      value: mover_booking.storage_size,
    },
    {
      label: 'Professional Packing',
      value: format.packing(mover_booking.professional_packing),
    },
    {
      label: 'Estimated Volume',
      value: mover_booking.cubic_feet && (`${mover_booking.cubic_feet} cf`),
    },
    {
      label: 'Box Count',
      value: mover_booking.box_count ? format.pluralize('box',mover_booking.box_count,true) : '',
    },
    {
      label: 'Inventory Count',
      value: mover_booking.inventory_count ? format.pluralize('item',mover_booking.inventory_count,true) : '',
    },
    !mover_booking.inventory_count && mover_booking.special_items && {
      label: 'Special Handling',
      value: mover_booking.special_items.map(field => field.label).join(', '),
      disclaimer: 'This does not represent an exhaustive list of inventory',
    },
    {
      label: mover_booking.inventory_count ? 'Additional Inventory' : 'Inventory List',
      value: mover_booking.inventory_text || mover_booking.inventory_textarea,
    },
  ].filter(f => f);

  return (
    <>

      { mover_booking.stops && (mover_booking.stops.length > 0) && (
        <>
          <h6 className='mb-20'>Addresses</h6>
          <div>
            <AddressList addresses={mover_booking.stops}/>
          </div>
        </>
      )}

      <h6 className='mt-30 mb-20'>Details</h6>
      <div>
        <FieldList fields={detailsFields}/>
      </div>

      <ExpandingList items={mover_booking.boxes} label={'Boxes List'}/>

      <ExpandingList items={mover_booking.inventory} label={'Inventory List'}/>

    </>
  );
}
