import { generate, request } from '@moved/services';

export const { action, reducer } = generate.redux({
  namespace: 'JOBS_GET_JOBS',
  request: (id,params={}) => request.get(`/vendors/jobs`,{params:params}).then(r => r.data),
  reducers: {
    success: (state, { response, params }) => {
      return {
        ...state,
        jobs: {
          params: params,
          totalResults: response.pagination.total_items,
          activeSet: response.data.vendor_jobs,
        },
      }
    }
  }
});

export const initialState = {
  jobs: {
    params: {},
    totalResults: 0,
    activeSet: [],
  },
};
