import { generate, request } from '@moved/services';
import moment from 'moment';

const creator = {
  namespace: 'AVAILABILITY_GET_CALENDAR',
  request: ({date = moment().format('YYYY-MM-DD')}) =>
    request.get(`/vendors/calendar`, {params: { date }}).then(r => r.data.data),
  reducers: {
    success: (state, { response: availability, params: [{date}] }) => {
      const activeDate = date || moment().format('YYYY-MM-DD');
      return {
        ...state,
        availability: availability,
        activeDate: activeDate,
      };
    },
    failure: (state) => {
      return {
        ...state,
        availability: initialState.availability,
      };
    }
  }
};

export const {
  action,
  reducer,
} = generate.redux(creator);

export const initialState = {
  availability: {
    vendor: null,
    schedule: [],
  },
};
