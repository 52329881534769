import React from 'react';
import Select from 'react-select';

const styles = {
  container: (provided, state) => ({
    ...provided,
    flex: '1 1 auto',
    fontSize: '14px',
  }),
  control: (provided, state) => ({
    ...provided,
    borderWidth: 0,
    backgroundColor: '#fff',
    cursor: 'pointer',
    height: '36px',
    minWidth: '110px',
    boxShadow: '0 0 0 transparent'
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '0 15px',
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 200,
    marginTop: '1px',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
  }),
  indicatorSeparator: (provided, state) => ({
    display: 'none',
  }),
  input: (provided, state) => ({
    ...provided,
    margin: '0',
    padding: '0',
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    right: 0,
  }),
};

export const HeaderSelect = ({name, value, options, onChange}) => {
  return (
    <Select
      name={name}
      value={value}
      options={options}
      onChange={onChange}
      styles={styles}
      isSearchable={false}
    />
  );
}
