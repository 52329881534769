/*
*   This component will render fairly arbitrary data in pre-styled "cards"
*   optimized for mobile layout. Often this is a mobile responsive replacement
*   for a table of data (created to make sortTable mobile friendly)
*   Props:
*     items: Array of data rows to populate the list of cards (one card per item),
*       ideally each item has an `id`
*     layout: This is an array of arrays. The outer array are rows to render within
*       the card, and the inner arrays are the columns for each row. Each column
*       should adhere to the typical field structure:
*          {
*            name: some unique identifier,
*            value: (item) => (...jsx...),
*            class(optional): classname for this field column
*          }
*
*/
import React from 'react';
import classNames from 'classnames';

import cardsCSS from './CardList.module.scss';

const hasRows = (item, layout) => layout.find(row => hasFields(item, row));
const hasFields = (item, row) => row.find(field => field.value(item));

export const CardList = ({ items, layout, className }) => {
  return (
    <div className={classNames(cardsCSS.card_list, className)}>
      { items.filter(item => hasRows(item, layout)).map((item, index) => (
        <div className={cardsCSS.card} key={item.id || index}>
          { layout.filter(row => hasFields(item,row)).map((row) => (
            <div className={cardsCSS.card_row} key={row.find(field => field.value(item)).name}>
              { row.map(field => (
                field.value(item) && (
                  <div key={field.name}
                    className={classNames(cardsCSS.card_field,field.class)}>
                    { field.value(item) }
                  </div>
                )
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  );

};
